import React, {useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import './App.css';
import {Button, FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';

function App() {
	const theme = createTheme({
		palette: {
			mode: 'dark',
			primary: {
				main: purple[500]
			},
			neutral: {
				main: '#a7a7a7',
      	contrastText: '#fff',
			}
		}
	})
	const [selectedValue, setSelectedValue] = React.useState(105);
	const [actualRun, setActualRun] = React.useState(0);
	const [totalRuns] = React.useState(4);
	const [timerValue, setTimerValue] = React.useState(0);
	const [actualRound, setActualRound] = React.useState(0);
	const [totalRounds] = React.useState(25);
	const [pauseTime] = React.useState(300);
	const [startTime, setStartTime] = React.useState(60);
	const [isStart, setIsStart] = React.useState(false);
	const [isFinished, setIsFinisched] = React.useState(false);
	const [isPause, setIsPause] = React.useState(false);
	const [isActive, setIsActive] = React.useState(false);
	const decrementStartVal = () => setStartTime(startTime - 1);
	const incrementRun = () => setActualRun(actualRun + 1);
	const incrementRound = () => setActualRound(actualRound + 1);
	const incrementTimerVal = () => setTimerValue(timerValue + 1);
	const decrementRun = () => setActualRun(actualRun - 1);
	const decrementRound = () => setActualRound(actualRound - 1);
	const decrementTimerVal = () => setTimerValue(timerValue - 1);

	useEffect(() => {
		if(isFinished) return
		let timer;
		if(isActive){
			if(!isPause) {
				timer = setTimeout(() => {
					setTimerValue(normalTimerRun)
				}, 1000)	
			} else {
				timer = setTimeout(() => {
					setTimerValue(pauseTimerRun)
				}, 1000)
			}
		}
			return () => clearTimeout(timer)
	}, [isActive, timerValue]);

	useEffect(() => {
		if(!isStart) return
		const timer = setTimeout(() => {
			setStartTime(preStartTimer)
		}, 1000)
		return () => clearTimeout(timer)
	}, [isStart, startTime])

	useEffect(() => {
		setTimerValue(selectedValue)
	}, [])

	const handleChange = (event) => {
		setSelectedValue(event.target.value)
		setTimerValue(event.target.value)
	}

	const startTimer = () => {
		if(isActive) return
		setIsStart(true)
	}

	const stopTimer = () => {
		setIsActive(false)
		setIsPause(false)
		setIsFinisched(false)
		setIsStart(false)
		const timer = setTimeout(() => {
				setTimerValue(selectedValue)
				setActualRound(0)
				setActualRun(0)
				setStartTime(60)
		}, 500)
		return () => clearTimeout(timer)
	}

	function reset() {
		setIsActive(false)
		setIsPause(false)
		setIsFinisched(false)
	}

	function preStartTimer() {
		if(startTime === 1) {
			setIsStart(false)
			setTimerValue(selectedValue)
			reset()
			setActualRound(1)
			setActualRun(1)
			setIsActive(true)
		}
		decrementStartVal()
	}
	
	function pauseTimerRun() {
		if(timerValue === 1) {
			setIsPause(false)
			setTimerValue(selectedValue)
			if(actualRun === totalRuns) {
				setIsFinisched(true)
				return
			}
			incrementRun()
			setActualRound(1)
			return
		}
		decrementTimerVal()
	}

	function normalTimerRun() {
			if(timerValue === 1) {
				setTimerValue(selectedValue);
				if(actualRound < totalRounds) {
					incrementRound()
				} else {
					if(actualRun === totalRuns) {
						setIsFinisched(true)
						setTimerValue(selectedValue)
						setIsActive(false)
						return
					}
					displayPause();
				}
				return
			}
			decrementTimerVal()
	}

	function displayPause() {
		setIsPause(true);
		setTimerValue(pauseTime);
	}

  return (
    <ThemeProvider theme={theme}>
			<div style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.primary.contrastText }} className="App">
				<div className="radiobuttonGroup">
					<FormControl component="fieldset">
						<RadioGroup 
							aria-label="seconds"
							name="seconds-radio-group"
							value={selectedValue}
								onChange={handleChange}
						>
						<FormControlLabel value="150" control={<Radio />} label="2:30" />
								<FormControlLabel value="135" control={<Radio />} label="2:15" />
									<FormControlLabel value="120" control={<Radio />} label="2:00" />
								<FormControlLabel value="105" control={<Radio />} label="1:45" />
						</RadioGroup>
					</FormControl>
					<div className="buttonDiv">
						<Button onClick={startTimer} variant="outlined" color="neutral" >START</Button>
						<Button onClick={stopTimer} variant="outlined" color="neutral">RESET</Button>
					</div>
				</div>
				<div className="timer">
					<div>
						<h1>Durchlauf: {actualRun} / {totalRuns}</h1>
					</div>
					<div>
					{ !isFinished ?
							<p className="timer-value" style={{color: timerValue < 6 ? theme.palette.error.dark : "white"}}>{Math.floor(timerValue / 60)} : {timerValue - Math.floor(timerValue / 60) * 60 < 10 && ("0") }{timerValue - Math.floor(timerValue / 60) * 60}</p>
						 :	<p className="timer-value">Ende</p>
					}
					</div>
					<div>
						<h1>{actualRound} / {totalRounds}</h1>
					</div>
					{isPause &&
						<div>
						<h1 className="blink-text">PAUSE</h1>
						</div>
					}
					{isStart &&
						<h1>Start in {startTime}</h1>
					}
				</div>
			</div>
    </ThemeProvider>
  );
}

export default App;
